<template>
    <div class="cloud-import">
        <AppTable
            primaryKey="id"
            :columns="columns"
            :rows="rows"
            class="cloud-import__table"
            @lazyload="onLazyload"
        >
            <template v-slot:status="scopeData">
                <div class="cloud-import__table-buttons">
                    <div
                        v-for="([label, color, count], index) in scopeData.row.status"
                        :key="index"
                        :style="{color: `#${color}`}"
                    >
                        {{label}}: {{count}}
                    </div>
                </div>
            </template>

            <template v-slot:type="scopeData">
                <div>
                    {{getTypeLabel(scopeData)}}
                </div>
            </template>

            <template v-slot:buttons="scopeData">
                <div class="cloud-import__table-buttons">
                    <BaseButton
                        :view="'third'"
                        form="square"
                        @click="onViewLogs(scopeData)"
                    >
                        Посмотреть
                    </BaseButton>
                </div>
            </template>

            <template
                v-for="(column, index) in ['created_at']"
                v-slot:[column]="scopeData"
            >
                <div :key="index">
                    <div>{{getFormattedDate(column, scopeData)}}</div>
                </div>
            </template>
        </AppTable>
    </div>
</template>

<script>
import AppTable from '@/components/Table2/Table.vue';
import ServiceCloudImport from "@/services/ServicesCloudImport/ServiceCloudImport";
import UtilDate from '@/utils/UtilDate';
import BaseButton from "@/components/Base/BaseButton.vue";
export default {
	name: "PageCloudImportLogs",
	components: {
        AppTable,
        BaseButton
	},
    /**
     * Входные данные компонента
     */
    props: {
        settingId: {
            type: Number,
            default: 0
        }
    },
	data() {
		return {
            columns: [],
            rows: [],
            page: null,
            pageCount: null,
            filterParams: {},
            sortParam: null,
		}
	},
	computed: {

	},
    beforeMount() {
        this.page = 1;
    },
    created() {

	},
	methods: {
        onLazyload(){
            if(this.page < this.pageCount){
                this.page++;
            }
        },

        getTypeLabel(scopeData){
            const {row = {}} = scopeData;
            const {type = "FILE"} = row;
            switch (type) {
                case "FILE":
                    return "Файл";
                case "FOLDER":
                    return "Папка";
                default:
                    return "";
            }
        },

        getFormattedDate(prop, scopeData){
            return UtilDate.getLocaleDateWithTime(scopeData.row[prop]);
        },

        onViewLogs(scopeData) {
            const {row = {}} = scopeData;
            const {id: taskId = 0} = row;
            this.$router.push({path: '/cloud-import/setting-log', query: {
                    taskId: Number(taskId)
                }});
        },

	},
	watch: {
        page() {
            if(this.page === null){
                this.rows = [];
                this.page = 1;
                return;
            }

            ServiceCloudImport.logList(this.settingId, this.page, {}, (result) => {
                const {pageCount = 0, items = [], columns = []} = result;
                this.rows = [...this.rows, ...items];
                this.columns = columns;
                this.pageCount = pageCount;
            }, () => {

            });
        }
	}
}
</script>

<style lang="scss" scoped>
.cloud-import {
    height: 100%;
    overflow-y: auto;
    padding: 20px;

    &__interval-container{
        display: flex;
        margin-bottom: 20px;
    }

    &__interval-button {
        padding: 5px;
        margin: 5px;
    }

    &__table {
        width: 100%;
        height: auto;
        max-height: 100vh;
    }

    &__table-buttons{
        display: flex;
        overflow-x: auto;
    }

    &__table-buttons .btn{
        margin: 2px;
        padding: 6px;
    }
}
</style>
